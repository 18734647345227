<template>
<div>
  <v-toolbar>
    <v-toolbar-title></v-toolbar-title>
    <v-autocomplete
      v-model="model"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      class="mx-4"
      flat
      hide-no-data
      hide-details
      label="Address"
      
    ></v-autocomplete>
    <v-btn
        :disabled="model === null"
        plain 
        @click="switchComponent('order-form');"
        class="ctlButton"
      >
        Check my Address
      </v-btn>
  </v-toolbar>
  <v-expand-transition>
      <v-list
        v-if="model"
        class="red lighten-3"
      >
        <v-list-item
          v-for="(field, i) in fields"
          :key="i"
        >
          <v-list-item-content>
            <v-list-item-title v-text="field.value"></v-list-item-title>
            <v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>
    <div v-if="errormsg!==null" style="color:red;margin-top:10px;">
      <span v-html="errormsg"></span>
    </div>
</div>
</template>
<style scoped>
input{font-size:16px;}
</style>


<script>
import { bus } from '../main.js';
  export default {
    data: () => ({
      descriptionLimit: 60,
      isShow:false,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      loading: false,
      select: null,
      errormsg:null
    }),
    props: {
        currentComp: {
            type: String,
            required: false
        }
    },
     computed: {
      fields () {/*
        if (!this.model) return []
        return Object.keys(this.model).map(key => {
          return {
            key,
            value: this.model[key] || 'n/a',
          }
        })*/
        return this.entries;
  //        return this.select;
      },
      items:{
        get(){
          return this.entries;
        },
        set(val){
          this.entries = val;
        }
        
      },
     },
    watch: {
      search (val) {
          if(val.length>=3){
            val && val !== this.select && this.querySelections(val)
          }
      },
    },
    methods: {
        switchComponent(comp) {
            bus.$emit('switchComp', comp);
            this.returnAddress();
        },
      
        returnAddress(){
            bus.$emit('returnAddr',this.model);
        },
        
      querySelections (v) {
        this.loading = true
        

        // Items have already been loaded
       // if (this.items.length > 0) return

        // Items have already been requested
        if (this.isLoading) return

        this.isLoading = true
        
        // Lazily load input items

        fetch("https://prod-166.westus.logic.azure.com/workflows/7c43dbd01a28498682aee2ec47ed4b97/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=oi2hgJ7lNkIwrJ8BRPLLklitQZEsZifH64QD_kXr3fE",
        
            {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    "searchTerm": v
                })
            }
        )
          .then(function(res) {
                return res.json();
            })
          .then(
            
            res => {

            this.entries=[];
            this.count = Object.keys(res).length
            //display message if no results
            if (this.count==0){
              this.errormsg="We do not recognize this address. Please type and select a valid address or visit <a href='https://www.centurylink.com'>www.centurylink.com</a>. "
            }else this.errormsg=null;
            res.forEach(element => {
                this.entries.push(element.address);   
            });
            console.log(this.entries);
            this.items=this.entries;
          }
          
          )
          .catch(err => {
            console.log(err)
          })
          .finally(() => (this.isLoading = false))
          return this.entries;
          
      },
    },


   
  }
</script>