<template>
    <div>
      <div class="main-content">
        <h1>Thank you!</h1>
        <p>Someone will contact you shortly to discuss your needs.</p>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'thank-you',
  }
</script>

<style scoped></style>