<template>
  <div data-app>
    <div class="nav">
      <div class="header">
        <img src="./assets/corp_logo.svg" />
      </div>
    </div>
    
    <div id="initalContent">
        <h1>CenturyLink is bringing faster Internet service to your community as part of our Connect America Fund broadband expansion.</h1>
        
        <div class="main-content">
          <p>The wait is over. Now you can experience real speed in your neighborhood because of our upgraded network. So, go ahead, stream movies, video chat with your friends and family - even stay connected to the latest news with CenturyLink<span v-html="regball"></span> Internet. </p>

          <p>After all, we believe everyone deserves a connection they can count on. That's why we invested in your community - to get you connected to what matters most. </p>

          <p>Plus, with CenturyLink you can choose from a range of plans and services to fit your lifestyle and budget. Let us help you find the plan that's right for you. </p>

          <div class="features">
            <img src="@/assets/Cloud.png" width="100" /><br />
            Stay connected to family and friends as much as you like.
          </div>
          <div class="features">
            <img src="@/assets/Shopping.png" width="100" /><br />
            Work from home, shop and pay bills online with ease.
          </div>
          <div class="features">
            <img src="@/assets/TV.png" width="100" /><br />
            Access news, entertainment and more on your connected devices.
          </div>
          
          <div class="features">
            <img src="@/assets/WiFi.png" width="100" /><br />
            Freely move from room to room with in-home WiFi. 
          </div>
          <div class="main-content" id="addrForm">
            <address-field></address-field>
          </div>
        </div>
    </div>
        
    <!--display after address is submitted-->

    <order-form v-if="currentComp=='order-form'" :address="returnAddr"></order-form>

    <thank-you v-if="currentComp=='thank-you'"></thank-you>


    <hr style="border-color:#666;" />
    <div class="footer">
      
    <span v-html="copyright"></span>
    </div>
  </div>
  
</template>


<script>
import AddressField from "./components/AddressField";

import OrderForm from './components/OrderForm.vue';
import ThankYou from './components/ThankYou.vue';
import { bus } from './main.js';

export default {
  name: "App",
  components: {
    'address-field' : AddressField,
    'order-form' : OrderForm,
    'thank-you' : ThankYou,
  },
  data() {
    return {
      currentComp: '',
      returnAddr: null,
      copyright: '&copy; '+new Date().getFullYear()+' CenturyLink. All Rights Reserved.',
      regball: '<sup>&reg;</sup>',
    };
  },
  created() {
      bus.$on('switchComp', comp => {
           this.currentComp = comp;
           window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
           console.debug("Current Comp: "+this.currentComp);
      }),
      bus.$on('returnAddr', addr => {
           this.returnAddr = addr;
           window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      })
      

  },
   methods: {
    
  }

};
</script>

<style>
@font-face {
    font-family: 'Maison Neue Book';
    src: url('./assets/fonts/MaisonNeue-Book.otf') format('opentype'),
    url('./assets/fonts/MaisonNeue-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Maison Neue Light';
    src: url('./assets/fonts/MaisonNeue-Light.otf') format('opentype'),
    url('./assets/fonts/MaisonNeue-Light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Maison Neue Medium';
    src: url('./assets/fonts/MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/MaisonNeue-Medium.otf') format('opentype'),
    url('./assets/fonts/MaisonNeue-Medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Maison Neue Bold';
    src: url('./assets/fonts/MaisonNeue-Bold.otf') format('opentype'),
    url('./assets/fonts/MaisonNeue-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Maison Neue Book Superscript';
    src: url('./assets/fonts/MaisonNeueExtendedThin.otf') format('opentype'),
    url('/assets/fonts/MaisonNeueExtendedThin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'MaisonNeue';
	src: url('./assets/fonts/MaisonNeue-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/MaisonNeue-Medium.otf') format('opentype'),
    url('./assets/fonts/MaisonNeue-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "Maison Neue Extra Bold";
	src: url('./assets/fonts/MaisonNeue-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/MaisonNeue-ExtraBold.otf') format('opentype'),
    url('./assets/fonts/MaisonNeue-ExtraBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}
@font-face {
    font-family: 'Maison Neue Thin';
    src: url('./assets/fonts/MaisonNeueThin.otf') format('opentype'),
    url('./assets/fonts/MaisonNeueThin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.ctlButton{
    float: none!important;
    background-color: #48D597;
    color: black;
    position: relative;
    padding-top: 11px;
    font-size: 16px;
    font-family: "Maison Neue Bold", Arial, Helvetica, sans-serif;
    font-weight: bold;
    border: 2px solid #48D597;
    border-radius: 0;
    width: 200px;
    height: 43px;
}
.ctlButton:hover {
    float: none!important;
    background-color: #48D597;
    color: black;
    position: relative;
    padding-top: 11px;
    font-size: 16px;
    font-family: "Maison Neue Bold", Arial, Helvetica, sans-serif;
    font-weight: bold;
    border: 2px solid #48D597;
    border-radius: 0;
    width: 200px;
    height: 43px;
}
#app {
  margin-top: 60px;
  font-family: Maison Neue Medium;
  font-size:18px;
}
.footer, input,p,label,.v-list-item__content{
  font-family: Maison Neue Medium;
}
h1 {
    font-size: 30px;
    color: #0047BB;
    line-height: 1.4em;
    max-width: 990px;
    margin: 20px auto 20px;
    font-family:Maison Neue Medium;
}
div.header{
    max-width: 1020px;
    overflow: visible;
    position:relative;
    padding:15px;
    margin:auto;
}
div.nav{
  box-shadow: 1px 4px 3px #666;
}
div.main-content{
  max-width: 1020px;
  overflow: visible;
  position:relative;
  padding:15px;
  margin:auto;
  font-size: 18px;
  color: #53565A;
  text-align: left;
  line-height: 20px;
  clear:both;
}
div.main-content p{
  margin-bottom:1.2em;
  font-size: 18px;
  font-family: Maison Neue Medium;
}
.features{
  font-size: 18px;
  font-family: Maison Neue Medium;
  width:50%;
  float:left;
  padding:10px;
}
div.footer{
  clear:both;
  max-width: 1020px;
  overflow: visible;
  position:relative;
  padding:15px;
  margin:auto;
}

</style>