<template>
    <div refs="scroll">
      <div class="speed-content">
        
        <h1 style="line-height:1.3em;">You're eligible for a download speed of at least 10 Mbps and upload speed of 1 Mbps</h1>
        <br clear="all" />
      </div>
      <div class="horizontalStripe">
            <span class="navyStripe"></span>
            <span class="vibrantStripe"></span>
            <span class="mintStripe"></span>
        </div>
        <div class="pageContent">
          <div class="formWrapper">
            <div class="infoHeader">
                <div class="inFoHeadWrap">
                    <span>Want to know more?</span>
                    <span class="tickIcon"></span>
                </div>
              </div>
                <div class="horizontalLine"></div>
                <form method="post" id="covid-19-relief-form" name="covid-19-relief-form" class="infoForm">
                <div class="form-text">Address: {{address}}</div>
                <div v-if="formError!==null" style="color:red;margin-top:10px;">
                  <span v-html="formError"></span>
                </div>
                <div class="relief-form-control">
                  <label for="firstName">First Name*</label>
                  <input type="text" name="firstName" id="firstName" class="user-field-required" placeholder="First Name*" v-model="firstName">
                </div>
                <div class="relief-form-control">
                  <label for="lastName">Last Name*</label>
                  <input type="text" name="lastName" id="lastName" class="user-field-required" placeholder="Last Name*" v-model="lastName">
                </div>
                <div class="relief-form-control">
                  <label for="lastName">Email Address*</label>
                  <input type="text" name="email" id="lastName" class="user-field-required" placeholder="Email*" v-model="emailAddr">
                </div>
                <div class="relief-form-control">
                  <label for="tn">Telephone Number*</label>
                  <input type="tel" name="tn" id="tn" placeholder="Telephone Number*" class="user-field-required" maxlength="13" v-model="phoneNum"><div class="helperText" v-mask="['(###) ###-####']">(123) 456-7890</div>
                  <div class="inputInstrMsg address">Example: 1234567890 or 303-123-4567</div>
                </div>
                <div class="relief-form-control" id="submit-block-wrapper">
                  <label for="sendMyRequest" hidden="">Submit request form</label>
                  <v-btn
                    plain 
        
                    class="ctlButton"
                    @click="formSubmit();"
                  >Submit</v-btn>
                </div>
                <div class="privacyLink-block">
                  <div class="lockIcon"></div>
                  <div class="privacy-linkWrapper">
                    How does CenturyLink protect my information? <br class="visible-xs">Read the <a href="https://www.centurylink.com/aboutus/legal/privacy-policy.html" class="privacy-link" target="_blank" data-galink-type="text">CenturyLink Privacy Policy</a>
                  </div>
                </div>
                </form>
            </div>
          </div>
        </div>
</template>

<script>

import { bus } from '../main.js';
import {mask} from 'vue-the-mask'

  export default {
    name: 'order-form',
    directives:{mask},
    data: () => ({
      firstName: '',
      lastName: '',
      emailAddr: '',
      phoneNum: '',
      formError: null

    }),
    props: {
        currentComp: {
            type: String,
            required: false
        },
        'address':{type:String}
    },
    mounted:function(){
      window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    },
    methods: {
        switchComponent(comp) {
            bus.$emit('switchComp', comp);
        },
        formSubmit(){
          //make sure all fields are completed
          if(this.firstName=='' || this.lastName=='' || this.emailAddr==''){
            //display error and return false
            this.formError="Please complete all fields.";
            return false;
          }else if(this.firstName.length<2 || this.lastName.length<2){
            this.formError="Name must be at least 2 characters long."
            return false;
          }
          else if(this.emailAddr.search('@')==-1 || this.emailAddr.search('.')==-1){
            this.formError="Email address must contain \"@\" and \".\".";
            return false;
          }
          else if(this.phoneNum.length!=10){
            this.formError="Phone number must be 10 digits.";
            return false;
          }
          //submit the data to the api
           fetch("https://prod-181.westus.logic.azure.com:443/workflows/d16dfec4bdd84251bc86ae7b2891bf6c/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=WjzkIfeA4jwILGNY4N6uhqrAKiMugu4rlYhKcn9DK3I",
            
                {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        "address": this.address,
                        "firstName": this.firstName,
                        "lastName": this.lastName,
                        "CBR": this.phoneNum,
                        "email": this.emailAddr,
                        "speed": "10MB"
                    })
                }
            )
              .catch(err => {
                console.log(err)
              })
              .finally(() => this.switchComponent('thank-you'));   
                       
        }
    }
  }
</script>

<style scoped>
.horizontalStripe {
    height: 5px;
    width: 100%;
}
.navyStripe, .vibrantStripe, .mintStripe {
    height: 5px;
    float: left;
}
.navyStripe {
    width: 15%;
    background-color: #001e60;
}
.vibrantStripe {
    width: 35%;
    background-color: #0047bb;
}
.mintStripe {
    width: 50%;
    background-color: #48d597;
}
.speed-content{
  width:687px;
  margin:auto auto 30px auto;
}
.pageContent {
    background-color: rgba(238, 238, 238, 0.7);
    margin: 0 auto;
    padding-top: 50px;
    clear:both;
    padding-bottom:20px;
}
div.formWrapper {
    width: 687px;
    margin: 0 auto 50px;
    border-radius: 14px;
    box-shadow: 2px 2px 5px 0 rgb(0 0 0 / 35%);
    background-color: #FFFFFF;
}

.infoHeader {
    width: 100%;
}
.inFoHeadWrap {
    margin: 40px auto 20px;
    width: auto;
    text-align:center;
}
.infoHeader span {
    display:inline-block;
    font-size: 30px;
    line-height: 40px;
    padding-right: 15px;
    padding-top: 5px;
    margin-top:10px;
    font-family: Maison Neue Medium;
}
.infoHeader span.tickIcon, .tickIcon.tick-successMsg {
    background-image: url(../assets/checkmark_icon.svg);
    width: 45px;
    height: 45px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding: 0px;
    background-size: contain;
    vertical-align:top;
    
}
.horizontalLine {
    border-top: 2px solid #48D597;
    width: 535px;
    margin: 0 auto 15px;
    clear:both;
}
.form-text{
    font-family: Maison Neue Medium;
    font-size: 14px;
    color: #999999;
    margin-top: 23px;
}
.formWrapper label {
    font-size: 14px;
    color: #999999;
    margin-bottom: 0;
    margin-top: 20px;
    visibility: hidden;

    font-weight: normal;
}
.formWrapper input {
    border: 0;
    outline: 0;
    background-color: #EEEEEE;
    border-bottom: 1px solid #000000;
    padding: 0px 15px;
    width: 100%;
    height: 45px;
    font-size: 16px;
    box-shadow: none;

    color: #000000;
    line-height: 44px;
}
.helperText {
    display: none;
    color: #666666;
    font-size: 10px;
    margin: 8px 0 0;
}
.relief-form-control .inputInstrMsg {

    font-size: 12px;
    color: #999999;
    margin-top: 3px;
}
.relief-form-control{
  clear:both;
}
.infoForm {
    width: 475px;
    margin: 0px auto;
}
#submit-block-wrapper {
    text-align: center;
    margin-bottom: 25px;
}
#sendMyRequest {
    margin-left: auto;
    margin-right: auto;
}
.infoForm input.ctlHoverButton, .infoForm input.ctlHoverButton:focus, .infoForm input.ctlHoverButton:visited, .infoForm input.ctlHoverButton:active {
    background-color: #48D597;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    border: none;
    width: 200px;
    height: 50px;
    margin-top: 20px;
    border: 2px solid #48D597;
}
.privacyLink-block {
    margin: 0px 0px 40px 0px;
}
.lockIcon {
    background-image: url(../assets/lock_icon.svg);
    width: 12px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    background-size: contain;
}
.privacy-linkWrapper {
    margin: 0px 0px 0px 23px;
    color: #000000;
    font-size: 11.5px;
    padding-top: 5px;
    font-family: Maison Neue Medium;
}
.infoForm {
    width: 475px;
    margin: 0px auto;
    padding-bottom:20px;
}
</style>